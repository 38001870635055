@import "~bootstrap/scss/bootstrap";

$navbar-height: 30px;

$primary-background: #7c719c;
$secondary-background: #e6e6e6;

$text-error: #ffd000;

* {
  font-family: "Open Sans", sans-serif;
}
h3 {
  font-family: "Italianno", cursive;
  font-size: 2.75rem;
}

.body {
  background-color: $primary-background;
  color: #ffffff;
}

.body-secondary {
  background-color: $secondary-background;
  color: #000000;
}

.text-muted {
  color: #eeeeee !important;
}

.text-required {
  color: $text-error;
}

.invalid-feedback {
  color: $text-error;
}

.body,
.body-secondary {
  padding: 30px 30px;
  // margin: auto 50px auto;
  margin: auto;
  max-width: 800px;
}

.navbar-spacer {
  margin-top: ($navbar-height + 34px);
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white !important;
  border-bottom: 2px solid #e5e5e5;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.navbar-logo {
  height: $navbar-height;
}

.social-link {
  display: block !important;
  margin: 0.5rem 1rem;
}
